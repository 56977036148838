/* Reason: make the little arrow hugging the left wall of graph popup
(containing score values) appear see component GraphPopupContainer */
.ant-drawer-content {
  overflow: visible !important;
}

/* Override the active color in the sidebar component */
.ant-layout-sider-dark
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #286123 !important;
}

.react-grid-item.react-grid-placeholder {
  background-color: #286123 !important;
}

/* Make react grid item move placeholder background green */
.react-grid-item.react-grid-placeholder {
  background: #409c46;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Override titlebar height to fit "logged in user avatar" */
.titlebar {
  height: 48px !important;
  background-color: #409c46;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.move-window {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}
.no-move-window {
  -webkit-user-select: none;
  -webkit-app-region: no-drag;
}

/* Insert main content furtherdown due to the taller titlebar (see above) */
.container-after-titlebar {
  inset: 48px 0px 0px !important;
}

.titlebar.cet-windows,
.titlebar.cet-linux {
  height: 48px !important;
  line-height: 48px !important;
}

.titlebar > .window-title {
  padding-left: 102px !important;
  cursor: default;
  margin-right: auto;
  margin-left: auto;
  color: white;
  font-size: 14px;
}

/* Container for the window buttons */
.windowButtonContainer {
  -webkit-app-region: no-drag;
  position: fixed;
  top: 8px;
  right: 8px;
  height: 48px;
  z-index: 1000000;
}

/* Container that fits the "logged in avatar" */
.loggedInContainer {
  /* Electron specific styling, ignores "move the window" in the container area */
  -webkit-app-region: no-drag;
  position: fixed;
  top: 8px;
  /* right: 155px; */
  right: 8px;
  height: 48px;
  z-index: 1000000;
}

/* Container that fits the "help switch" */
.helpSwitchContainer {
  /* Electron specific styling, ignores "move the window" in the container area */
  -webkit-app-region: no-drag;
  position: fixed;
  top: 11px;
  /* right: 205px; */
  right: 58px;
  height: 48px;
  z-index: 1000000;
}

.helpSwitchContainer .ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.5);
}

.helpPopupModal {
  position: absolute;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 10px;
  top: 50px;
  right: 24px;
  z-index: 5000;
  box-shadow: 0px 2px 12px -2px rgb(0 0 0 / 25%);
}

.helpPopupModal * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Custom table for recording parameters preview */
.mobitron-antd-table .ant-table-thead > tr > th {
  font-weight: 600;
}

/* Custom range picker in dashboard header */
.custom-tag-rangepicker .ant-picker-input > input {
  color: white;
  width: 100px;
  font-size: 12px;
}

/* Uses the same button styling in range picker as the rest of the application.
* !!Important: If you change this value, make sure to also change variable
* "baseButtonStyle" in component "CommonButtons.tsx"
*/
.ant-picker-ranges button {
  box-shadow: 0 1px 6px -2px rgb(0 0 0 / 25%);
}

/* The same box-shadow as NormalButton */
.ant-select {
  box-shadow: 0 1px 6px -2px rgb(0 0 0 / 25%);
}

/* Radio button is built to show buttons inline but we use them separately.
This is a fix to prevent a line to the left of the next button. */
.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.ant-radio-button-wrapper {
  border-left-width: 1px;
}

.ant-notification {
  z-index: 1050;
}

.ant-tree .ant-tree-switcher-noop {
  display: none;
}
