.rc-slider-track {
  background-color: #a4bd9f;
}
.rc-slider-handle {
  border: solid 2px #97c779;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #3e9736;
  box-shadow: 0 0 0 5px #97c779;
}
.rc-slider-handle-click-focused:focus {
  border-color: #97c779;
}
.rc-slider-handle:hover {
  border-color: #3e9736;
}
.rc-slider-handle:active {
  border-color: #3e9736;
  box-shadow: 0 0 5px #3e9736;
}
.rc-slider-dot-active {
  border-color: #97c779;
}
