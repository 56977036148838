body {
  /* overflow: hidden; */
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: start;
}

.App-link {
  color: #61dafb;
}

.Main-Menu {
  background-color: "#E0E0E0";
}

.Sub-Menu {
  background-color: "#E0E0E0";
}

/* doesent work yet */
.selectableText {
  color: black !important;
}
.selectableText:hover {
  color: #3e9736 !important;
}

.closeModalButton {
  cursor: pointer;
  border: none;
  color: rgba(0, 0, 0, 0.45);
  background-color: transparent;
  border-radius: 2px;
  transition: 0.2s;
  padding: 1px;
}

.closeModalButton:hover {
  color: black;
  background-color: #f0f0f0;
  border-radius: 2px;
}

.closeModalButton:active {
  color: black;
  background-color: #dbdbdb;
  border-radius: 2px;
}

.dangerButton {
  border-color: #d9d9d9 !important;
}

.dangerButton:hover {
  border-color: #ff4d4f !important;
}

.dangerButton:disabled {
  border-color: #d9d9d9 !important;
}

/* remove border between table row and it's below expandable content */
.tableRowActiveWithExpandable > td {
  border-bottom: 0px !important;
}
