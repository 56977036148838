/* Pulse effect that can be used for svg icons */
.pulse-svg > svg {
  animation: pulse 2s infinite forwards;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}
