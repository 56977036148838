.counter-icon {
  line-height: 26px;
  border-radius: 32px;
  color: #fff;
  background-color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: 3px solid #fff;
}
